import styled from 'styled-components';
import { color, fontSize, fontWeight, spacing } from 'theme';

// styled-components.
// -----------------------------------------------------------------------------
export const LandingNotificationRoot = styled.div`
  background-color: ${color('notice_200')};
  color: ${color('notice_900')};
  font-size: ${fontSize('sm')};
  line-height: 1.1;
  text-align: center;
  min-height: 3.5rem;

  p {
    margin: 0;
  }
`;

export const LandingNotificationWrapper = styled.div`
  display: flex;
  gap: ${spacing('xs')};
  max-width: 87.5rem;
  margin: 0 auto;
`;

export const LandingNotificationText = styled.div`
  padding: ${spacing('mdlg')};
  width: 100%;

  a {
    color: ${color('notice_900')};
    font-weight: ${fontWeight('extraBold')};
    text-decoration: underline;
  }
`;

export const LandingNotificationClose = styled.div`
  padding-top: ${spacing('xs')};
  padding-right: ${spacing('md')};

  button:hover {
    background-color: transparent;
  }

  svg {
    color: ${color('notice_900')};
  }
`;
