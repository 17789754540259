import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/shared/Button.styled';
import {
  LandingNotificationClose,
  LandingNotificationRoot,
  LandingNotificationText,
  LandingNotificationWrapper
} from './LandingNotification.styled';
import { LandingNotificationProps } from './LandingNotification.types';

// Componente.
// -----------------------------------------------------------------------------
export const LandingNotification: React.FC<LandingNotificationProps> = ({
  children,
  onClose
}) => (
  <LandingNotificationRoot>
    <LandingNotificationWrapper>
      <LandingNotificationText>{children}</LandingNotificationText>

      {!!onClose && (
        <LandingNotificationClose>
          <Button isIcon onClick={onClose} sizes="sm" variant="tertiary">
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </LandingNotificationClose>
      )}
    </LandingNotificationWrapper>
  </LandingNotificationRoot>
);

export default LandingNotification;
