import Button from 'components/shared/Button.styled';
import ButtonLink from 'components/shared/ButtonLink.styled';
import styled from 'styled-components';
import { color, fontSize, fontWeight, radius, screen, shadow, spacing } from '../../../theme';

export const StyledHeaderActions = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;

  ${Button},
  ${ButtonLink} {
    margin-left: ${spacing('md')};

    @media (max-width: ${screen('mdMax')}) {
      border: 0;
      margin-left: ${spacing('xs')};
      padding: ${spacing('xs')};

      span {
        display: none;
      }

      svg {
        width: ${spacing('lg')} !important;
        height: ${spacing('lg')};
        margin: 0;
      }
      
    }

  }

  .logged-actions {
    gap: 0.5rem;
    flex-wrap: nowrap;


    a {
      margin: 0 0.25rem;

      @media (max-width: ${screen('mdMax')}) {
        padding: 0.5rem;
      }
    }
  }

  .signin {
    @media (max-width: ${screen('mdMax')}) {
      display: none;
    }
  }
`;

export const StyledUser = styled.div`
  position: relative;
  margin-left: ${spacing('sm')};
`;

export const StyledUserTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  cursor: pointer;

  svg {
    width: ${spacing('sm')} !important;
    height: ${spacing('sm')};
  }
`;

export const StyledInitials = styled.div`
  width: ${spacing('xl')};
  height: ${spacing('xl')};
  border-radius: ${radius('full')};
  background-color: ${color('sand_150')};
  color: ${color('sand_500')};
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('medium')};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledUserOptions = styled.div`
  background-color: ${color('white')};
  padding: ${spacing('xs')};
  border-radius: ${radius('md')};
  box-shadow: ${shadow('lg')};
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
`;

export const StyledUserOption = styled.div`
  background-color: transparent; 
  border-radius: ${radius('md')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  transition: .2s;

  a {
    display: flex;
    color: inherit;
    transition: .2s;
    padding: 20px;
    min-width: 220px;
    gap: 8px;

    small {
      color: ${color('midnight_300')};
      font-size: ${fontSize('xs')};

      strong {
        color: ${color('text_active')};
        font-weight: ${fontWeight('regular')}
      }
    }

    &:hover {
      color: inherit;
    }
  }

  svg {
    flex-shrink: 0;
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
    color: ${color('midnight_300')};
    transition: .2s;
    margin-right: ${spacing('xs2')};
  }

  &:hover {
    background-color: ${color('midnight_150')};
    color: ${color('text_default')};
    cursor: pointer;

    svg {
      color: ${color('midnight_400')};
    }
  }

  &:active {
    background-color: ${color('midnight_200')};
    color: ${color('midnight_400')};

    svg {
      color: currentColor;
    }
  }

  &.active {
    background-color: ${color('active')};
    color: ${color('text_over_active')};

    svg {
      color: currentColor;
    }
  }

  &.logout,
  &.logout svg {
    color: ${color('negative_500')};

    &:hover,
    &:hover svg {
      color: ${color('navy_800')};
    }
  }
`;

export const StyledUserInfo = styled.div`
  padding: ${spacing('md')} ${spacing('md')} ${spacing('lg')};
`;

export const StyledUserName = styled.span`
  display: block;
  font-weight: ${fontWeight('black')};
  margin-bottom: ${spacing('sm')};
`;

export const StyledActionsNav = styled.div`
  border-bottom: 1px solid ${color('midnight_200')};
  border-top: 1px solid ${color('midnight_200')};
  padding-top: ${spacing('xs')};
  padding-bottom: ${spacing('xs')};
  margin-bottom: ${spacing('xs')};
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const StyledAvailability = styled.div`
  margin-top: ${spacing('md')};
  font-size: ${fontSize('xs')};
  color: ${color('text_subtle')};
  font-weight: ${fontWeight('medium')};
`;

export const StyledUserAvailability = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  font-size: ${fontSize('sm')};
  margin-top: ${spacing('xs2')};
`;
